<template>
  <div>
    <promotion-form :save="save" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PromotionForm from './promotion-form'

export default {
  name: 'PromotionEdit',
  components: { PromotionForm },
  async created() {
    document.title = 'Редактирование акции'
    const { id } = this.$route.params
    this.fetchById(id)
  },
  methods: {
    async save(image) {
      await this.$store.dispatch('promotion/form/update', image)
      await this.$router.push({ name: 'promotion-list' })
    },
    ...mapActions('promotion/form', ['fetchById']),
  },
}
</script>

<style scoped>

</style>
