<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <image-upload v-model="image" :width="375" :height="204" @formData-updated="imageUploaded"
                    @file-removed="imageRemoved"></image-upload>
    </v-card-text>

    <v-divider></v-divider>

    <v-card-text>
      <v-form class="multi-col-validation mt-6" ref="form">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <date-range
              v-model="dateRange"
              :rules="[rules.required]"
            ></date-range>

          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="sortIndex"
              :rules="[rules.required]"
              dense
              outlined
              label="Индекс сортировки"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-checkbox
              v-model="isBanner"
              label="Баннер"
              :error="hasErrorsForProperty(errors.errors, 'isBanner')"
              :error-messages="errorMessages(errors.errors, 'isBanner')"
              hide-details
            ></v-checkbox>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="contacts"
              :items="contactsDropdown"
              :menu-props="{ maxHeight: '400' }"
              label="Контакты"
              multiple
              :error="hasErrorsForProperty(errors.errors, 'contacts')"
              :error-messages="errorMessages(errors.errors, 'contacts')"
              dense
              outlined
              persistent-hint
              hide-details
            ></v-select>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-select
              v-model="restaurants"
              :items="restaurantDropDown"
              :menu-props="{ maxHeight: '400' }"
              label="Рестораны"
              multiple
              :error="hasErrorsForProperty(errors.errors, 'restaurants')"
              :error-messages="errorMessages(errors.errors, 'restaurants')"
              dense
              outlined
              persistent-hint
              hide-details
            ></v-select>
          </v-col>

          <v-spacer></v-spacer>

          <v-col
            md="12"
            cols="24"
          >
            <v-tabs
              v-model="tab"
              show-arrows
            >
              <v-tab v-for="(item, index) in translations" :key="index">
                <span>{{ item.language }}</span>
              </v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <v-tabs-items v-model="tab">
              <v-tab-item v-for="(item, index) in translations" :key="index">
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="item.name"
                    label="Наименование"
                    dense
                    outlined
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="item.subtitle"
                    label="Подзаголовок"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="24"
                  md="12"
                >
                  Описание
                  <tip-tap
                    :rules="[rules.required]"
                    v-model="item.description"
                  ></tip-tap>
                </v-col>
              </v-tab-item>
            </v-tabs-items>
          </v-col>

          <v-col cols="12">
            <v-btn
              @click="saveData"
              color="primary"
              class="me-3 mt-4"
            >
              Сохранить
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="mt-4"
              type="reset"
              @click="cancel"
            >
              Отмена
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheck, mdiClose } from '@mdi/js'
import { mapFormHandlers } from '@/helpers/forms'
import { mapActions, mapGetters } from 'vuex'
import DatePicker from '@/components/date-picker'
import { rules } from '@/helpers/validation'
import TipTap from '@/components/tip-tap'
import DateRange from '@/components/date-range'
import ImageUpload from '@/components/image-upload.vue'
import { hasErrorsForProperty, errorMessages } from '@/helpers'

export default {
  name: 'PromotionForm',
  props: ['save', 'show'],
  components: { TipTap, DatePicker, DateRange, ImageUpload },
  data: () => ({
    icons: {
      mdiCheck,
      mdiClose,
    },
    tab: null,
    rules,
    newImage: null,
    hasErrorsForProperty,
    errorMessages,
  }),
  computed: {
    ...mapFormHandlers('promotion/form', [
      'translations',
      'publicationDate',
      'contacts',
      'restaurants',
      'image',
      'sortIndex',
      'isBanner',
      'endDate',
      'dateRange',
    ]),
    ...mapGetters('dictionary', { contactsDropdown: 'contactsDropdown' }),
    ...mapGetters('restaurant', { restaurantDropDown: 'restaurantDropDown' }),
  },
  async mounted() {
    await this.fetchContacts()
    await this.fetchRestaurants()
  },
  methods: {
    imageUploaded(blob) {
      this.newImage = blob
    },
    imageRemoved() {
      this.newImage = null
    },
    cancel() {
      this.resetForm()
      this.$router.back()
    },
    ...mapActions('promotion/form', ['resetForm']),
    ...mapActions('dictionary', { fetchContacts: 'fetchContacts' }),
    ...mapActions('restaurant', { fetchRestaurants: 'fetchDropDown' }),
    async saveData() {
      if (this.$refs.form.validate()) {
        await this.save(this.newImage)
      }
    },
  },
}
</script>
