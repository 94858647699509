<template>
  <v-menu
    v-model="toShow"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="value"
        v-model="inputVal"
        :label="label"
        :prepend-icon="icons.mdiCalendar"
        readonly
        dense
        outlined
        :rules="rules"
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="inputVal"
      @input="handleInput"
    ></v-date-picker>
  </v-menu>
</template>

<script>

import {
  mdiCalendar,
} from '@mdi/js'
import { toDateSting } from '@/helpers'

export default {
  name: 'DatePicker',
  props: ['value', 'label', 'rules'],
  data() {
    return {
      content: this.value,
      toShow: false,
      icons: {
        mdiCalendar,
      },
    }
  },
  computed: {
    inputVal: {
      get() {
        return toDateSting(this.value)
      },
      set(val) {
        this.$emit('input', val)
      },
      formatDate(){
        toDateSting(this.value)
      }
    },
  },
  methods: {
    handleInput(e) {
      this.toShow = false
    },
  },
}
</script>

